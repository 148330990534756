.popup-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100%;
}

.popup-box {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 50px;
	align-items: center;
	width: 90%;
	/* margin: 100px auto; */
	/* padding: 10px; */
}

.popup-bubble {
	--r: 25px; /* the radius */
	--t: 15px; /* the size of the tail */

	padding: calc(2 * var(--r) / 3);
	-webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%)
			var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
		conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
			calc(var(--r) / -2) padding-box,
		radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
			padding-box;
	background: white;
	color: black;
	/* margin-top: 100px; */
	max-width: 600px;
	line-height: 30px;
	font-weight: bold;
}
.left {
	--_d: 0%;
	border-left: var(--t) solid #0000;
	margin-right: var(--t);
	/* place-self: start; */
}
.right {
	--_d: 100%;
	border-right: var(--t) solid #0000;
	margin-left: var(--t);
	/* place-self: end; */
}

.popup-btn {
	display: inline-block;
	text-align: center;
	margin: auto;
	padding: 10px 30px 10px 30px;
	margin-top: 20px;
	background-color: white;
	border-radius: 15px;
	font-family: 'Varela Round', sans-serif;
	font-size: 15px;
	font-weight: bold;
}
