*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 100%;
} /*16px*/

:root {
	--black: #222;
	--white: #fff;
	--red-light: #f8d7da;
	--red-dark: #842029;
	--green-light: #d1e7dd;
	--green-dark: #0f5132;

	--small-text: 0.875rem;
	--extra-small-text: 0.7em;
	/* rest of the vars */

	--border-radius: 0.25rem;
	--letter-spacing: 1px;
	--transition: 0.3s ease-in-out all;
	--max-width: 1120px;
	--fixed-width: 600px;
	--fluid-width: 90vw;
	--nav-height: 6rem;
	/* box shadow*/
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* body {
	color: var(--white);
	font-family: 'Varela Round', sans-serif;
	font-weight: bold !important;
	line-height: 1;
} */
body {
	direction: rtl;
	font-family: 'Varela Round', sans-serif;
	min-height: 100vh;
	color: var(--white);
	background-image: url('https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/background.jpg');
	background-size: cover;
	background-position: center;
	background-blend-mode: multiply;
}
/* .App {
	min-height: 100vh;
} */
.logo {
	padding: 20px 0 30px 0;
	width: 60%;
}
.playlist {
	max-width: 90%;
	border-radius: 15px;
}
.main {
	width: var(--fluid-width);
	max-width: var(--max-width);
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
}

ul {
	list-style: none;
	padding: 0 !important;
}

h1,
h3,
h2,
h4 {
	color: white !important;
	letter-spacing: 2.5px;
	line-height: 25px;
	margin: 10px;
}

h5 {
	color: #e0c389 !important;
	letter-spacing: 1.5px;
	margin: 4px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.vi__wrapper {
	margin: 20px 0 20px 0;
}
.ltr {
	direction: ltr;
	/* margin-bottom: 20px; */
}
.player-wrapper {
	padding-top: 20px;
	position: relative;
	display: flex;
	justify-content: center;
}

.player-wrapper video {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
		0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
		0 16px 16px rgba(0, 0, 0, 0.12);
}
.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.overlay-li {
	position: relative;
}
.overlay-in {
	display: inline-block;
	position: relative;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black background */
	pointer-events: none; /* Makes the overlay unclickable */
	z-index: 100; /* Ensure it's above the input element */
}
.checkmark {
	position: absolute;
	top: -20px;
	right: -20px;
	z-index: 0;
	pointer-events: none;
}
.checkmark-no-overlay {
	position: absolute;
	top: -5px;
	right: -20px;
	z-index: 2;
	pointer-events: none;
}
button.html5-qrcode-element {
	color: 'red';
	border: '1px solid red';
}
#html5-qrcode-anchor-scan-type-change {
	display: none !important;
}
.text-input {
	margin-top: 10px;
	height: 40px;
	width: 300px;
	line-height: 40px;
	font-size: 3rem;
	text-align: center;
}

.margin-top {
	margin-top: 20px;
}

.container-just {
	text-align: center;
	row-gap: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.admin-btn {
	position: absolute;
	top: 3px;
	right: 3px;
	height: 30px;
	font-weight: bold;
}

.stand-image {
	margin-top: 20px;
	width: 80%;
	height: 80%;
	object-fit: contain;
	border-radius: 60px;
}

.notification-container {
	position: sticky !important;
	top: -500px;
}

.qr-container {
	margin-top: 20px;
	height: 250px;
	width: 250px;
}

#reader > div:nth-child(1) {
	display: none !important;
}

#html5qr-code-full-region__scan_region {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
		0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
		0 16px 16px rgba(0, 0, 0, 0.12);
}

.shadow-5 {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
		0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
		0 16px 16px rgba(0, 0, 0, 0.12);
}

.notification-container {
	position: fixed !important;
}

@media screen and (min-width: 900px) {
	.logo {
		width: 30%;
	}
}
