#reader {
	border: 0 !important;
}

#reader__dashboard_section_fsr span {
	display: none !important;
}

#reader__dashboard_section {
	padding: 0 !important;
}

#reader__dashboard_section_swaplink {
	text-decoration: none !important;
	border-radius: 2px;
	margin-top: 1rem;
	color: #fff;
	background-color: #1989fa;
	border: 1px solid #1989fa;
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	height: 2.5rem;
	width: 88%;
	line-height: 2.5rem;
	text-align: center;
	cursor: pointer;
	align-items: center;
	/* -webkit-appearance: none; */
	-webkit-box-pack: center;
}

#reader__dashboard_section_csr button {
	display: inline-block;
	text-align: center;
	margin: auto;
	padding: 10px 30px 10px 30px;
	margin-top: 20px;
	background-color: white;
	border-radius: 15px;
	font-family: 'Varela Round', sans-serif;
	font-size: 15px;
	font-weight: bold;
}

#reader__dashboard_section_csr span {
	margin-right: 0 !important;
}

#reader__dashboard_section_fsr input {
	text-decoration: none !important;
	border-radius: 2px;
	margin-top: 1rem;
	color: #fff;
	background-color: #07c160;
	border: 1px solid #07c160;
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	height: 2.5rem;
	width: 88% !important;
	line-height: 2.5rem;
	text-align: center;
	cursor: pointer;
	align-items: center;
	/* -webkit-appearance: none; */
	-webkit-box-pack: center;
}

#reader__camera_selection {
	background-color: #fff;
	height: 2.5rem;
	width: 88%;
	line-height: 2.5rem;
	border: 1px solid #1989fa;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

#html5qr-code-full-region {
	border: none !important;
}

#html5qr-code-full-region > div:nth-child(1) > img {
	display: none;
}

#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-start {
	visibility: hidden;
}

#html5-qrcode-button-camera-stop:after,
#html5-qrcode-button-camera-start:after {
	visibility: visible;
	display: block;
	padding: 10px 30px 10px 30px !important;
	background-color: white;
	border-radius: 15px;
	font-family: 'Varela Round', sans-serif;
	font-size: 15px;
	font-weight: bold;
}

#html5-qrcode-button-camera-stop:after {
	content: 'הפסק סריקה';
}

#html5-qrcode-button-camera-start:after {
	content: 'התחל סריקה';
}
